// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/Navbar.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Assuming an AuthContext for managing auth
import '../styles/scss/navbar.scss';
import Spinner from 'react-bootstrap/Spinner';
import { Modal, Button } from 'react-bootstrap';


const LogoutConfirmationModal = ({ show, onHide, onConfirm }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Se déconnecter</Modal.Title>
    </Modal.Header>
    <Modal.Body>Voulez-vous vraiment vous déconnecter ?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>Annuler</Button>
      <Button variant="danger" onClick={onConfirm}>CONFIRMER</Button>
    </Modal.Footer>
  </Modal>
);


const Navbar = ({ language, changeLanguage, translations }) => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Update isLoggedIn based on the token in localStorage
  }, [setIsLoggedIn]);

  const handleLogout = async () => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    setIsLoggedIn(false);
    setLoading(false);
    navigate('/');
  };
  

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle between English and French
  const toggleLanguage = () => {
    const newLanguage = language === 'EN' ? 'FR' : 'EN';
    changeLanguage(newLanguage); // Use changeLanguage passed from App
  };

   // Language icons
   const languageIcon = language === 'EN'
   ? 'https://afaa.website/s/ddbd46.png' // English icon
   : 'https://afaa.website/s/54f790.png'; // French icon


   if (loading) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  


  return (    
    <>    
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
       
          <Link to="/" className="navbar-brand">
            <img src="https://afaa.website/s/9ce743.png" alt="Mirabeau thaï spa Logo" className="img-fluid img-logo" />
          </Link>
       
        <button className="navbar-toggler" type="button" onClick={toggleMenu}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* Dynamically render menu items based on the current language */}
            <li className="nav-item"><Link className="nav-link" to="/">{translations[language].HOME}</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/booking">{translations[language].BOOK}</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/gallery">{translations[language].GALLERY}</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/contact">{translations[language].CONTACT}</Link></li>

            
            {isLoggedIn ? (
              <>
                <li className="nav-item"><Link className="nav-link" to="/profile">{translations[language].PROFILE}</Link></li>
        

                <li className="nav-item">
                    <Link className="nav-link" onClick={() => setShowLogoutModal(true)}>{translations[language].LOGOUT}</Link>
                  </li>

              </>
            ) : (
              <>
                <li className="nav-item"><Link className="nav-link" to="/login">{translations[language].LOGIN}</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/register">{translations[language].REGISTER}</Link></li>

               
                  
              </>
            )}
            {/* Language switch button */}
          
            <li className="nav-item">
              <button className="nav-link btn" onClick={toggleLanguage} style={{ background: 'none', border: 'none' }}>
                <img src={languageIcon} alt="Language Mirabeau thaï spa" className="language-icon" />
              </button>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>

    <LogoutConfirmationModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        onConfirm={() => {
          setShowLogoutModal(false);
          handleLogout();
        }}
        />

    </>
  );
};

export default Navbar;
