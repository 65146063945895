//var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/utilities/clearAllFromIndexedDB.js

// Import the initDB function from where it's defined
import { initDB } from './indexedDBUtils'; // Adjust the path as needed

export const clearAllFromIndexedDB = async (objectStoreName) => {
    const db = await initDB();
    const transaction = db.transaction([objectStoreName], 'readwrite');
    const objectStore = transaction.objectStore(objectStoreName);
  
    return new Promise((resolve, reject) => {
        const clearRequest = objectStore.clear(); // Clear all entries in the store
    
        clearRequest.onsuccess = () => {
            console.log(`${objectStoreName} store cleared.`);
            resolve();
        };
    
        clearRequest.onerror = (event) => {
            console.error(`Error clearing ${objectStoreName} store:`, event.target.error);
            reject(event.target.error);
        };
    });
};
