// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/SpeedDialComponent.js
import React, { useRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { SpeedDial } from 'primereact/speeddial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpa, faCreditCard, faFileInvoice, faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'primereact/badge';

import '../styles/scss/SpeedDialComponent.scss';

const SpeedDialComponent = ({ onSpeedDialSelect, count }) => {
  const [visible, setVisible] = useState(false);

  const speedDialItems = [

    {
      label: 'Shopping',
      icon: <FontAwesomeIcon icon={faCartArrowDown} />,
      command: () => onSpeedDialSelect('Shopping'), // Triggers parent component logic
      dataLabel: 'Shopping'
    },

   
    {
      label: 'Payment',
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      command: () => onSpeedDialSelect('payment'), // Triggers parent component logic
      dataLabel: 'Payment'
    },

    {
      label: 'Invoice',
      icon: <FontAwesomeIcon icon={faFileInvoice} />,
      command: () => onSpeedDialSelect('invoice'), // Triggers parent component logic
      dataLabel: 'Invoice'
    },

  ];

  const speedDialButtonTemplate = (options) => (
    <div className="speed-dial-badge-container">
      {count > 0 && <Badge value={count} severity="danger" className="speed-dial-badge" />}
      <button
        className={`p-speeddial-action-button p-button p-component ${options.className}`}
        style={options.style}
        onClick={options.onClick}
        onTouchEnd={options.onClick}
      >
        <FontAwesomeIcon icon={faSpa} />
      </button>
      <span className="speed-dial-label">{options.dataLabel}</span>
    </div>
  );
  

  const toggleSpeedDial = () => {
    setVisible(prevVisible => !prevVisible);
  };

  useEffect(() => {
    const actionBtn = speedDialRef.current;
    if (actionBtn) {
      actionBtn.addEventListener('touchend', toggleSpeedDial);
      return () => actionBtn.removeEventListener('touchend', toggleSpeedDial);
    }
  }, []);

  const speedDialRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <div className="speed-dial-custom-position" ref={containerRef}>
      <Draggable
        axis="both"
        handle=".p-speeddial-action-button"
        defaultPosition={{ x: 0, y: 0 }}
        grid={[1, 1]}
        scale={1}
      >
        <div ref={speedDialRef}>
          <SpeedDial
            model={speedDialItems}
            visible={visible}
            onHide={() => setVisible(false)}
            onShow={() => setVisible(true)}
            buttonTemplate={speedDialButtonTemplate}
          />
        </div>
      </Draggable>
    </div>
  );
};

export default SpeedDialComponent;
