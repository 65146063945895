// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/MySwiper.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Removed Link since it's not used
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../styles/scss/home.scss';
import { Dropdown } from 'primereact/dropdown';

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';

const MySwiper = () => {

  const [branches, setBranches] = useState([]);  // State to store branch data
  const [selectedBranch, setSelectedBranch] = useState(null);  // State to handle the selected branch

  const navigate = useNavigate();  // Get the navigate function for navigation

  // Fetch branches data when component mounts
  useEffect(() => {
    fetch('https://py.testproject.work/branches/api/view')
      .then(response => response.json())
      .then(data => {
        // Filter branches with ID 1, 2, and 3
        const filteredData = data.filter(branch => [1, 2, 3].includes(branch.branch_ID));
        // Format the filtered data
        const formattedData = filteredData.map(branch => ({
          name: branch.branch_name, 
          id: branch.branch_ID
        }));
        setBranches(formattedData);  // Set the formatted data
      })
      .catch(error => console.error('Error fetching branch data:', error));
  }, []);
  
  // Handle selection of a branch from the dropdown
  const handleBranchChange = (e) => {
    const selectedBranch = e.value;
    setSelectedBranch(selectedBranch);  // Update selected branch
    console.log(`Selected branch: ${selectedBranch.name}`); // Log the selected branch

    // Save selected branch details to localStorage
    localStorage.setItem('selectedBranch', JSON.stringify({
      name: selectedBranch.name,
      id: selectedBranch.id
    }));

    // Redirect to the booking page
    navigate('/booking');
  };

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2300,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <img
          className='img-slide-index1'
          src="https://afaa.website/s/3ee8be.webp"
          alt="Banner 1"
        />
        <div className="slide-caption animate-text">
          <h1>Bienvenue chez ISAANSPA</h1>
          <h3>ISAANSPA: Soins relaxants pour le corps et l'esprit, harmonie et bien-être au rendez-vous.</h3>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <img
          className='img-slide-index1'
          src="https://afaa.website/s/746c81.webp"
          alt="Mirabeau thaï spa"
        />
      </SwiperSlide>

      <SwiperSlide>
        <img
          className='img-slide-index1'
          src="https://afaa.website/s/744165.webp"
          alt="Mirabeau thaï spa"
        />
      </SwiperSlide>

      {/* Place the button outside the individual SwiperSlides */}
      <div className="booking-container">
        <Dropdown
          value={selectedBranch}
          options={branches}
          onChange={handleBranchChange}
          optionLabel="name"  // Ensure this matches the correct key in your data
          placeholder="BOOKING NOW"
          className="booking-dropdown booking-dropdown-trigger booking-btn position-absolute start-50 translate-middle"
        />
      </div>
    </Swiper>
  );
};

export default MySwiper;
