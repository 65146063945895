///var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/utilities/swiperUtils.js

import { useRef } from 'react';

export const useSwiperRef = () => {
  const swiperRef = useRef(null);

  const initSwiper = (swiper) => {
    swiperRef.current = swiper;
    const today = new Date();
    today.setDate(today.getDate() - 4);
    swiper.slideTo(today.getDate() - 1, 1000);
  };

  const slidePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const slideNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const slideToSelectedDate = (selectedDate) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const selectedIndex = swiper.slides.findIndex((slide) =>
        slide.classList.contains('selected-day')
      );
      if (selectedIndex !== -1) {
        swiper.slideTo(selectedIndex, 500);
      }
    }
  };

  return {
    swiperRef,
    initSwiper,
    slidePrev,
    slideNext,
    slideToSelectedDate,
  };
};
