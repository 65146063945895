// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/utilities/saveBookingStep.js

import { useNavigate } from 'react-router-dom';
import { fetchAllBookings } from '../utilities/IndexedDBAll.js'; // Re-import fetchAllBookings function


// Function to transform booking data to the new format
const transformBookingData = (allBookings) => {
  return allBookings.map(booking => ({
    BookingID: parseInt(booking.id.split('-')[1]), // Assuming ID format is consistent and can extract numeric part
    ServiceID: 2, // Example static value, adjust as needed
    EmployeeID: 3, // Example static value, adjust as needed
    ServiceDate: booking.date, // Use date from original data
    ServiceTime: booking.time, // Use time from original data
    ServiceStatus: 1, // Example static value, adjust as needed
    TransactionID: 4, // Example static value, adjust as needed
    GiftRecipientID: 6, // Example static value, adjust as needed
    note: "Special instructions here" // Example static value, adjust as needed
  }));
};

// This function now correctly logs and handles temporaryBookings along with bookingData
export const saveBookingStep = async (bookingData, temporaryBookings) => {
  console.log('Saving booking data:', bookingData);
  console.log('Temporary bookings:', temporaryBookings); // Correctly logs the temporary bookings

  // Fetch and log all bookings before saving new data
  try {
    const allBookings = await fetchAllBookings();
    console.log('All bookings:', allBookings);
    const transformedBookings = transformBookingData(allBookings);
    console.log('Transformed bookings to new format:', transformedBookings);
  } catch (error) {
    console.error('Failed to fetch and transform all bookings:', error);
  }

  // Simulate API call with a delay
  await new Promise(resolve => setTimeout(resolve, 1000));
  console.log('Booking data saved successfully.');

  // Perform any additional operations as needed  
};

// Custom hook to include temporaryBookings in save and navigate operation
export const useSaveBookingAndNavigate = () => {
  const navigate = useNavigate();

  const saveAndNavigate = async (bookingData, temporaryBookings, path) => {
    await saveBookingStep(bookingData, temporaryBookings); // Ensures temporaryBookings is passed
    navigate(path); // Continues to navigate to the specified path
  };

  return saveAndNavigate;
};
