// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/OffcanvasContent.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductItem from '../components/ProductItem';
import { Button } from 'primereact/button';
import { deleteFromIndexedDB } from '../utilities/indexedDBUtils';
import Badge from 'react-bootstrap/Badge';

const OffcanvasContent = ({ checkedStates, getProductDetails, deleteProduct }) => {
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0); // State to hold the total price
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetails = async () => {
            //setIsLoading(true);
            try {
                const products = await Promise.all(
                    Object.entries(checkedStates)
                        .filter(([_, isChecked]) => isChecked)
                        .map(async ([priceID]) => {
                            const details = await getProductDetails(priceID);
                            return { ...details, id: priceID };
                        })
                );
                setProductList(products);
            } catch (error) {
                console.error("Error fetching product details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductDetails();
    }, [checkedStates, getProductDetails]);

    useEffect(() => {
        // Calculate total price whenever productList updates
        const total = productList.reduce((acc, product) => acc + product.price, 0);
        setTotalPrice(total);
    }, [productList]);

    const handleReserveButtonClick = () => navigate('/booking/step1');

    const handleDelete = async (id) => {
        try {
            await deleteFromIndexedDB(id);
            deleteProduct(id);
        } catch (error) {
            console.error('Failed to delete from IndexedDB:', error);
        }
    };

    return (
        <div className="offcanvas-content">
            {isLoading ? (
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <>
                    {productList.map(product => (
                        <ProductItem
                            key={product.id}
                            product={product}
                            onDelete={() => handleDelete(product.id)}
                        />
                    ))}
                    {/* Display the total price */}                  
                    <h4>
                    Prix Total: <Badge bg="secondary">{totalPrice} €</Badge>
                    </h4>
                </>
            )}
            {!isLoading && (
                <Button
                    label="Réserver"
                    icon="pi pi-calendar"
                    className="p-button-help p-button-reserver"
                    onClick={handleReserveButtonClick}
                />
            )}
        </div>
    );
};

export default OffcanvasContent;
