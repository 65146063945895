// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/TimelineBookingStatus.js

import React from 'react';
import { Timeline } from 'primereact/timeline';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import '../styles/scss/timeline.scss';

const TimelineBookingStatus = ({ bookedStatuses, notBookedWidth, applyPadding }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const events = [
    { status: 'SERVICE', label: 'Service', path: '/booking' },
    { status: 'BOOK', label: 'Réserver', path: '/booking/step1' },
    { status: 'PAYMENT', label: 'Paiement', path: '/payment/step2' },
    { status: 'SUCCESS', label: 'Réussir', path: '/payment/invoice' },
  ];

  const customizedMarker = (item) => {
    const isActive = bookedStatuses.includes(item.status);
    return (
      <span
        className={`p-timeline-event-marker ${isActive ? 'active' : ''}`}
        onClick={() => handleNavigation(item.path)}
      >
        {/* Marker content here */}
      </span>
    );
  };

  const handleNavigation = (path) => {
    if (path) {
      navigate(path); // Use the navigate function to change the route
    }
  };

  const customizedContent = (item) => {
    const isBooked = bookedStatuses.includes(item.status);
    const style = isBooked ? {} : { width: notBookedWidth };

    if (applyPadding && !isBooked) {
      style.paddingRight = '50px';
    }

    return (
      <div className={`pointer custom-content ${isBooked ? 'booked' : 'not-booked'}`} style={style} onClick={() => handleNavigation(item.path)}>
        <span>{item.label}</span>
      </div>

    );
  };

  return (
    <div className="">
      <Timeline value={events} align="top" layout="horizontal" content={customizedContent}   marker={customizedMarker} />
    </div>
  );
};

export default TimelineBookingStatus;
