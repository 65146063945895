// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/DateSelectorSwiper.js

import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useSwiperRef } from '../utilities/swiperUtils';

const DateSelectorSwiper = ({ selectedDate, setSelectedDate }) => {
    const [monthOptions, setMonthOptions] = useState([]);
    const { swiperRef, initSwiper, slidePrev, slideNext, slideToSelectedDate } = useSwiperRef();

    const handleTodayClick = useCallback(() => {
        setSelectedDate(new Date());
    }, [setSelectedDate]);

    const handleDateClick = useCallback((date) => {
        const clickedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        setSelectedDate(clickedDate);
    }, [setSelectedDate]);

    const handleMonthChange = (e) => {
        const newDate = new Date(selectedDate.getFullYear(), e.value, selectedDate.getDate());
        setSelectedDate(newDate);
    };

    const generateSwiperSlides = useCallback(() => {
        const daysInMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate();

        return Array.from({ length: daysInMonth }, (_, index) => {
            const day = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), index + 1);
            const formattedDate = day.toLocaleDateString('fr-FR', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
            });

            return {
                key: day.toISOString(),
                content: (
                    <div
                        className={`swiper-slide-content ${isSameDay(day, selectedDate) ? 'selected-day' : ''}`}
                        onClick={() => handleDateClick(day)}
                    >
                        {formattedDate}
                    </div>
                ),
            };
        });
    }, [selectedDate, handleDateClick]);

    useEffect(() => {
        const months = Array.from({ length: 12 }, (v, i) => {
            const month = new Date(0, i).toLocaleString('default', { month: 'long' });
            const year = selectedDate.getFullYear();
            return { label: `${month} ${year}`, value: i };
        });
        setMonthOptions(months);
    }, [selectedDate]);

    useEffect(() => {
        slideToSelectedDate(selectedDate);
    }, [selectedDate, slideToSelectedDate]);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            const selectedIndex = swiper.slides.findIndex((slide) =>
                slide.classList.contains('selected-day')
            );
            if (selectedIndex !== -1) {
                swiper.slideTo(selectedIndex, 500);
            }
        }
    }, [selectedDate, swiperRef]);

    return (
        <div className="row">
            <div className="col-12 col-sm-2 date-picker-custom mobile-mb-15">
                <Dropdown
                    value={selectedDate.getMonth()}
                    options={monthOptions}
                    onChange={handleMonthChange}
                    placeholder="Select a Month"
                />
                <button onClick={handleTodayClick} className="today-button">
                    <FontAwesomeIcon icon={faCalendarDays} className="button-icon" /> Today
                </button>
            </div>
            <div className="col-12 col-sm-10 mobile-ml-2">
                <FontAwesomeIcon
                    icon={faCaretLeft}
                    className="swiper-navigation-prev gradient-icon"
                    size="6x"
                    onClick={slidePrev}
                />
                <Swiper
                    onSwiper={initSwiper}
                    spaceBetween={50}
                    slidesPerView={7}
                    breakpoints={{
                        320: { slidesPerView: 4, spaceBetween: 10 },
                        480: { slidesPerView: 3, spaceBetween: 20 },
                        640: { slidesPerView: 5, spaceBetween: 30 },
                        992: { slidesPerView: 7, spaceBetween: 50 },
                    }}
                >
                    {generateSwiperSlides().map((slide) => (
                        <SwiperSlide key={slide.key}>{slide.content}</SwiperSlide>
                    ))}
                </Swiper>
                <FontAwesomeIcon
                    icon={faCaretRight}
                    className="swiper-navigation-next gradient-icon"
                    size="6x"
                    onClick={slideNext}
                />
            </div>
        </div>
    );
};

const isSameDay = (a, b) => a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

export default DateSelectorSwiper;
