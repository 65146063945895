// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/ProductItem.js

import React from 'react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import '../styles/scss/ProductItem.scss';

const ProductItem = ({ product, onDelete }) => {
    return (
        <div className="product-item">
            <div className="product-details">
                {/* Use FontAwesomeIcon for the user icon */}
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <div className="product-name">{product.name}</div>
                <div className="product-duration">{product.duration}</div>
            </div>
            <div className="product-action">
                <div className="product-price">
                    {product.price} €
                </div>
                {/* Show badge only if quantity is greater than 1 */}
                {product.quantity > 1 && <Badge value={product.quantity} severity="info" className="quantity-badge"></Badge>}
                {/* Use FontAwesomeIcon for the trash icon */}
                <Button className="p-button-rounded p-button-danger delete-button" icon={() => <FontAwesomeIcon icon={faTrash} />} onClick={onDelete} />
            </div>
        </div>
    );
};

export default ProductItem;
