// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/CookiesConsent.js

// /src/components/CookiesConsent.js

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'; // Importing Bootstrap Button
import Modal from 'react-bootstrap/Modal'; // Importing Bootstrap Modal
import '../styles/scss/CookiesConsent.scss'; // Importing the SCSS file

const CookiesConsent = () => {
  const [consent, setConsent] = useState(localStorage.getItem('cookies-consent') || 'false');
  const [show, setShow] = useState(consent === 'false'); // Control visibility of the modal

  useEffect(() => {
    if (consent === 'true') {
      console.log('Cookies consent granted');
    }
  }, [consent]);

  const handleAccept = () => {
    setConsent('true');
    setShow(false);
    localStorage.setItem('cookies-consent', 'true');
  };

  const handleDecline = () => {
    setConsent('false');
    setShow(false);
    localStorage.setItem('cookies-consent', 'false');
  };

  return (
    <Modal
      show={show}
      onHide={handleDecline}
      backdrop="static"
      keyboard={false}
      className="cookies-modal"
      dialogClassName="cookies-modal-dialog" // Custom class for modal size
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Cookies Consent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We use cookies to improve your experience on our site. By continuing, you agree to our 
          <a href="/cookies-policy">Cookie Policy</a>.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleAccept}>
          Accept
        </Button>
        <Button variant="secondary" onClick={handleDecline}>
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CookiesConsent;
