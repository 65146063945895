// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/components/SomeOtherInvoice.js

import React from 'react';
import InvoiceComponent from './InvoiceComponent';


const SomeOtherInvoice = ({ language }) => {

  console.log('Language:', language);

  const invoiceData = {
    invoiceNumber: '1779012',
    invoiceDate: 'Wednesday 17 March 2024',
    clientDetails: {
      name: 'Mirabeau thai spa',
      address: '10 RUE MIRABEAU 75016 PARIS',
      email: 'info@isarnspa.com',
      phone: '01 42 30 78 28',
    },
    paymentDetails: {
      status: 'Waiting for payment',
      accountName: 'ISAANSPA ISAANSPA',
      orderId: '1234',
    },
    items: [
      {
        description: "Hot Oil Massage ",
        quantity: 2,
        service: "1 hr, Solo",
        price: 99
      },
      {
        description: "Traditional Thai Massage",
        quantity: 1,
        service: "1.5 hr, Solo",
        price: 139
      },
      {
        description: "Traditional Thai Massage",
        quantity: 3,
        service: "30 min, Duo",
        price: 119
      },
      {
        description: "Hot Oil Massage ",
        quantity: 2,
        service: "2 hr, Duo",
        price: 189
      }
    ],
    notes: 'IMPORTANT NOTES...',
    subtotal: '9,999 €',
    tax: '99 €',
    total: '99,999 €',
  };

  return (
    <div>
       <InvoiceComponent invoiceData={invoiceData} language={language} />
    </div>
  );
};

export default SomeOtherInvoice;
