// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/components/DataTableBooking.js


export const constructDataTableArray = (aggregatedData, serviceID) => {
    const serviceData = aggregatedData[serviceID] || {};
    let dataTableArray = [];

    Object.entries(serviceData).forEach(([priceType, priceForObj]) => {
        Object.entries(priceForObj).forEach(([priceFor, prices]) => {
            prices.forEach(price => {
                dataTableArray.push({ ...price, PriceType: priceType, PriceFor: priceFor });
            });
        });
    });

    return dataTableArray;
};

