// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/BookingScheduleStep.js

import React, { useState, useEffect } from 'react';
import 'swiper/css';
import { API_URLS } from '../api/api';
import TimelineBookingStatus from '../components/TimelineBookingStatus';
import SpeedDialComponent from '../components/SpeedDialComponent';
import { useSaveBookingAndNavigate } from '../utilities/saveBookingStep';
import { ProgressSpinner } from 'primereact/progressspinner'; // Import ProgressSpinner for loading indicator
import { fetchAllBookings } from '../utilities/IndexedDBAll.js'; // Re-import fetchAllBookings function
import OffcanvasBookingStep from '../components/OffcanvasBookingStep';
import { useDispatch } from 'react-redux';
import DateSelectorSwiper from './DateSelectorSwiper'; // Import the new component

import 'react-datepicker/dist/react-datepicker.css';
import '../styles/scss/main.scss';
import '../styles/scss/BookingSchedule.scss';

import axios from 'axios';
import { useSwiperRef } from '../utilities/swiperUtils';

import { simulateHappyHourData } from '../utilities/happyHourUtils'; // Import the function
import DataTableBookingTimeTable from '../components/BookingScheduleStep/DataTableBookingTimeTable';
import { handleSpeedDialSelect } from '../utilities/bookingUtils';  // Only import what's needed


// Function to get the branch ID from local storage
function getBranchID() {
  const selectedBranch = JSON.parse(localStorage.getItem('selectedBranch'));
  return selectedBranch ? selectedBranch.id : null;
}

// Updated simulateJsonData function to include branch_ID from local storage
const simulateJsonData = async (token, selectedDate) => {
  try {
    const branchID = getBranchID(); // Retrieve branch ID from local storage
    
    // Check if selectedDate is undefined or null, if so throw an error
    if (!selectedDate) {
      throw new Error("Selected date is undefined or null");
    }
    
    const formattedDate = selectedDate.toISOString().split('T')[0]; // Format selectedDate to YYYY-MM-DD

    console.log('API URL:', API_URLS.BOOKING_AVAILABLE); // Print the API URL
    console.log('branchID param:', branchID); // Print the branchID after retrieving it
    console.log('selectedDate param:', formattedDate); // Print the formatted date

    const response = await axios.get(API_URLS.BOOKING_AVAILABLE, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        branch_ID: branchID, // Add branch_ID as a query parameter
        date_from: formattedDate, // Add selectedDate as date_from parameter
        date_to: formattedDate, // Add selectedDate as date_to parameter
      },
    });

    return response.data;
  } catch (error) {
    console.error('Failed to fetch booking data:', error);
    return {}; // Return an empty object in case of an error
  }
};


let totalServices = 0;


const BookingScheduleStep = () => {
  // Component state initialization
  const [times, setTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [temporaryBookings, setTemporaryBookings] = useState({});
  const [bookingCount, setBookingCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  //const swiperRef = useRef(null);

  // Use the swiperRef hook to get swiper-related functions
  const {
    swiperRef,
    slideToSelectedDate,
  } = useSwiperRef();

  const saveAndNavigate = useSaveBookingAndNavigate();
  const [allBookings, setAllBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token') || '';

  const onSpeedDialSelect = async (action) => {
    await handleSpeedDialSelect(
      action,
      selectedBookings,
      totalServices,
      token,
      saveAndNavigate,
      dispatch,
      setIsLoading,
      setShowOffcanvas
    );
  };  

  // Updated handleAvailabilityChange function
  const handleAvailabilityChange = (timeSlot, isChecked) => {
    const formattedDate = selectedDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

    // Calculate the current count of selected slots
    const currentCount = Object.values(temporaryBookings[formattedDate] || {})
      .filter(isSelected => isSelected).length;

    // If trying to select more than allowed, show an alert and do not proceed



    if (isChecked && currentCount >= totalServices) {
      alert(`You cannot select more than ${totalServices} slots as per your reserved services.`);
      return;
    }

    // Update UI as previously done
    const updatedData = times.map((item, index) => {
      if (item.time === timeSlot) {
        // Log action
        //console.log(`${formattedDate} - Time slot ${timeSlot} (index ${index}) is now ${isChecked ? 'available' : 'booked/unavailable'}`);
        return { ...item, status: isChecked ? 'available' : 'booked' };
      }
      return item;
    });
    setTimes(updatedData);

    // Update temporary booking data
    const newBookings = { ...temporaryBookings, [formattedDate]: { ...(temporaryBookings[formattedDate] || {}), [timeSlot]: isChecked } };
    setTemporaryBookings(newBookings);

    // Log for inspection
    //console.log("Temporary bookings:", newBookings);

    // Calculate the new count based on updated bookings across all dates
    const newCount = Object.keys(newBookings).reduce((acc, date) => {
      return acc + Object.values(newBookings[date]).filter(isAvailable => isAvailable).length;
    }, 0);

    // If the new count exceeds the total services, revert the changes and alert the user
    if (newCount > totalServices) {
      alert(`You cannot select more than ${totalServices} slots as per your reserved services.`);
      // Revert the temporary booking for the current slot
      setTemporaryBookings({ ...temporaryBookings, [formattedDate]: { ...(temporaryBookings[formattedDate] || {}), [timeSlot]: !isChecked } });
      return;
    }

    // Update booking count only if it does not exceed total services
    setBookingCount(newCount);
  };



  useEffect(() => {
    async function fetchAndMapSelectedBookings() {
      try {
        // Fetch all bookings
        const fetchedBookings = await fetchAllBookings();

        // Create a map to lookup bookings by key
        const bookingLookup = new Map();
        const bookingOrder = [];
        fetchedBookings.forEach((booking) => {
          const key = `${booking.id}-${booking.name}`;
          bookingLookup.set(key, booking);
          bookingOrder.push(key); // Keep the order of keys
        });

        // Initialize variables
        let selectedIndex = 0;
        const newSelectedBookings = [];
        const usedIndices = new Set();

        // Helper function to check if the selected slot is Happy Hour

        function checkHappyHour(date, time) {
          //console.log(`Checking for Happy Hour - Date: ${date}, Time: ${time}`);
          const formattedDate = formatDate(date); // เรียกใช้ฟังก์ชัน formatDate เพื่อปรับรูปแบบของวันที่
          const dailyHappyHourData = simulateHappyHourData()[formattedDate] || [];
          const isHappyHourSlot = dailyHappyHourData.some(hhSlot => hhSlot.time === time);

          //console.log(`Happy Hour data for date ${date}:`, JSON.stringify(dailyHappyHourData, null, 2));
          //console.log(`Happy Hour status for selected slot: ${isHappyHourSlot}`);
          return isHappyHourSlot;
        }

        // ฟังก์ชันที่ใช้ในการปรับรูปแบบของวันที่
        function formatDate(date) {
          const [month, day, year] = date.split('/');
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }


        const extractNumericId = (id) => {
          const match = id.match(/\d+$/); // Regular expression to match the numeric part at the end of the string
          return match ? parseInt(match[0], 10) : null; // Convert the matched part to an integer
        };

        // Iterate through temporaryBookings to build newSelectedBookings
        for (const [date, times] of Object.entries(temporaryBookings)) {
          for (const [time, isSelected] of Object.entries(times)) {
            if (isSelected) {
              //console.log(`User selected: Date: ${date}, Time: ${time}`);
              const lookupKey = `${date}-${time}`;
              const foundBooking = bookingLookup.get(lookupKey);

              // Check if the selected slot is Happy Hour
              const isHappyHourSlot = checkHappyHour(date, time);
              //console.log(`Happy Hour status for selected slot: ${isHappyHourSlot}`);

              if (foundBooking) {
                const numericId = extractNumericId(foundBooking.id); // Extract numeric id
                newSelectedBookings.push({
                  index: selectedIndex,
                  id: numericId, // Use only the numeric part of the id
                  name: foundBooking.name,
                  time,
                  date,
                  happyHour: isHappyHourSlot
                });
              } else {
                const nonDuplicateBooking = fetchedBookings.find(b => !usedIndices.has(b.id));
                if (nonDuplicateBooking) {
                  const numericId = extractNumericId(nonDuplicateBooking.id); // Extract numeric id
                  newSelectedBookings.push({
                    index: selectedIndex,
                    id: numericId, // Use only the numeric part of the id
                    name: nonDuplicateBooking.name,
                    time,
                    date,
                    happyHour: isHappyHourSlot
                  });
                  usedIndices.add(nonDuplicateBooking.id);
                } else {
                  // For unknown IDs, handle appropriately. Assuming numericId would be null.
                  newSelectedBookings.push({
                    index: selectedIndex,
                    id: 'Unknown ID', // Placeholder, adjust as necessary
                    name: 'Unknown Name',
                    time,
                    date,
                    happyHour: isHappyHourSlot
                  });
                }
              }

              selectedIndex++; // Increment after each push
            }
          }
        }

        // Update state with newSelectedBookings
        setSelectedBookings(newSelectedBookings);
      } catch (error) {
        console.error("Error while fetching and mapping selected bookings:", error);
      }
    }

    // Call the fetchAndMapSelectedBookings function when temporaryBookings changes
    fetchAndMapSelectedBookings();
  }, [temporaryBookings]);

  // Show Offcanvas automatically when bookingCount > 1
  useEffect(() => {
    setShowOffcanvas(bookingCount >= 1);
  }, [bookingCount]);



  useEffect(() => {
    const fetchBookingData = async () => {
      // Check if selectedDate is valid before proceeding
      if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
        console.error('Invalid selected date:', selectedDate);
        return;
      }

      const formattedDate = selectedDate.toISOString().split('T')[0];
      console.log("Selected date:", formattedDate);

      try {
        const bookingData = await simulateJsonData(token, selectedDate);
        const bookingDataForSelectedDate = bookingData[formattedDate] || [];
        console.log('Booking data for selected date:', bookingDataForSelectedDate);

        const generatedTimeSlots = generateTimeSlots(selectedDate, bookingDataForSelectedDate);
        console.log("Generated time slots:", generatedTimeSlots);

        setTimes(generatedTimeSlots);
      } catch (error) {
        console.error('Error fetching booking data:', error);
      }
    };

    fetchBookingData();
  }, [selectedDate, token]);




  // Handle swiper slide to the selected day
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      const selectedIndex = swiper.slides.findIndex((slide) =>
        slide.classList.contains('selected-day')
      );
      if (selectedIndex !== -1) {
        swiper.slideTo(selectedIndex, 500);
      }
    }
  }, [selectedDate, swiperRef]);



  // Fetch totalServices once on component mount
  useEffect(() => {
    const fetchAndSetTotalServices = async () => {
      const allBookings = await fetchAllBookings();
      //console.log('All bookings:', allBookings);
      totalServices = allBookings.length;
      //console.log("Total services fetched and set:", totalServices);
    };

    fetchAndSetTotalServices();
  }, []);

  useEffect(() => {
    const fetchAndSetAllBookings = async () => {
      const bookings = await fetchAllBookings();
      setAllBookings(bookings);
    };

    fetchAndSetAllBookings();
  }, []);

  
  // This function simulates fetching Happy Hour data
  const fetchHappyHourData = (date) => {
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate());
    const formattedDate = nextDay.toISOString().split('T')[0];

    return simulateHappyHourData()[formattedDate] || [];
  };

  useEffect(() => {
    slideToSelectedDate(selectedDate);
  }, [selectedDate, slideToSelectedDate]);

  const generateTimeSlots = (selectedDate, bookingDataForDate) => {
    // Generate slots starting from 11 AM up to but not including 9 PM
    const startHour = 11;
    const endHour = 21;
    const slots = [];

    // Use a Map for quick lookup of booking data
    const bookingMap = new Map(bookingDataForDate.map(slot => [slot.time, slot.status]));

    for (let hour = startHour; hour < endHour; hour++) { // Notice the < operator
      slots.push({
        "time": `${hour.toString().padStart(2, '0')}:00`,
        "status": bookingMap.get(`${hour.toString().padStart(2, '0')}:00`) || 'unavailable'
      });
      slots.push({
        "time": `${hour.toString().padStart(2, '0')}:30`,
        "status": bookingMap.get(`${hour.toString().padStart(2, '0')}:30`) || 'unavailable'
      });
    }

    // Include the endHour but only the 00 minute mark
    slots.push({
      "time": `${endHour.toString().padStart(2, '0')}:00`,
      "status": bookingMap.get(`${endHour.toString().padStart(2, '0')}:00`) || 'unavailable'
    });

    return slots;
  };


  return (
    <div className="schedule-step container box-main-index1">

      <TimelineBookingStatus
        bookedStatuses={['SERVICE', 'BOOK']}
        notBookedWidth="100%"
        applyPadding={true}
      />

      {/* Speed Dial Component */}
      <SpeedDialComponent onSpeedDialSelect={onSpeedDialSelect} count={bookingCount} className="speed-dial-custom-position" />


      {/* Other components remain unchanged */}
      <OffcanvasBookingStep
        position="end"
        visible={showOffcanvas}
        onHide={() => setShowOffcanvas(false)}
        allBookings={allBookings}
        selectedBookings={selectedBookings}
        handleSpeedDialSelect={onSpeedDialSelect}
      />
      {/* Other components remain unchanged */}



      <div className='box-main-index2'>
        <DateSelectorSwiper selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      </div>


      {/* Display the spinner when isLoading is true */}
      {isLoading && (
        <div className="loading-overlay">
          <ProgressSpinner />
        </div>
      )}

      <DataTableBookingTimeTable
        times={times}
        selectedDate={selectedDate}
        temporaryBookings={temporaryBookings}
        handleAvailabilityChange={handleAvailabilityChange}
        fetchHappyHourData={fetchHappyHourData}
      />

    </div>
  );
};

export default BookingScheduleStep;