import React from 'react';

const Services = () => {
  return (
    <div>
      <h2>Our Services</h2>
      <ul>
        <li>Massage Therapy</li>
        <li>Facials</li>
        <li>Body Treatments</li>       
        {/* Include a contact form or more contact information here */}
      </ul>
    </div>
  );
}

export default Services;
