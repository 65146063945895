import React, { useEffect, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/scss/navbar_profile.scss';

const NavbarProfile = ({ onLogout }) => {
 
    const navigate = useNavigate();
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        {label: 'PROFILE', icon: 'pi pi-user', command: () => navigate("/profile")},
        {label: 'ORDER', icon: 'pi pi-shopping-cart', command: () => navigate("/order")},
      
    ];

    // Update activeIndex based on the current route
    useEffect(() => {
      const normalizePath = (path) => {
          // Remove trailing slash if present
          let normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
          // Further normalization logic can be added here if needed
          return normalizedPath;
      };
  
      const path = normalizePath(location.pathname);
      const indexMap = { "/profile": 0, "/order": 1 };
      const newIndex = indexMap.hasOwnProperty(path) ? indexMap[path] : -1;
      setActiveIndex(newIndex);
  }, [location]);
  

    return (
        <div className="navbar-profile-tabmenu">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </div>
    );
};

export default NavbarProfile;
