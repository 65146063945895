// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/PaymentForm.js

import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { clearAllFromIndexedDB } from '../utilities/clearAllFromIndexedDB';
import { Toast } from 'primereact/toast'; 
import '../styles/scss/PaymentForm.scss';

const STORE_NAME = 'bookings';

const PaymentForm = ({ 
  savedBookingID, 
  customerEmail, 
  totalAmount, 
  subTotalAmount, 
  dateTime, 
  showRegistrationModal, 
  telephone, 
  firstName, 
  surName
}) => {

    const [formData, setFormData] = useState({
        customerEmail,
        customerReference: savedBookingID,
        totalAmount,
        subTotalAmount,
        dateTime,
        first_name: firstName,
        sur_name: surName,
        telephone,
    });

    const [submitStatus, setSubmitStatus] = useState(null);
    const toast = useRef(null);

    const stripe = useStripe();
    const elements = useElements();

    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            customerReference: savedBookingID,
        }));
    }, [savedBookingID]);

    // เรียก API เพื่อสร้าง client_secret
    useEffect(() => {
      if (totalAmount && savedBookingID) {
        const customerID = localStorage.getItem('userData'); // ดึง customerID จาก localStorage
        const amountString = parseFloat(totalAmount).toFixed(2); // format amount เป็น "1.00"
        
        const paymentData = {
          amount: amountString,
          currency: "eur",
          user_ID: customerID,
          order_id: savedBookingID,
          employee_ID: "0" // กำหนด employee_ID = 0
        };

        fetch('https://py.testproject.work/payments/API/create_intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(paymentData),
        })
        .then(res => res.json())
        .then(data => {
          if (data.client_secret) {
            setClientSecret(data.client_secret);
          } else {
            console.error('No client_secret returned from API');
            // อาจแจ้งเตือนผู้ใช้หรือ handle กรณีนี้ตามจำเป็น
          }
        })
        .catch(err => console.error('Error fetching payment intent:', err));
      }
    }, [totalAmount, savedBookingID]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    async function sendSmsNotification(paymentData) {
        try {
            const orderId = paymentData?.order_id || 'Unknown Order ID';
            const amountPaid = paymentData?.subTotalAmount || 'Unknown Amount';
            const formattedTelephone = telephone.replace(/\s/g, '');
            const prefixedTelephone = formattedTelephone.startsWith('0') ? '+33' + formattedTelephone.substring(1) : formattedTelephone;

            const smsData = {
                sms_recipients: [prefixedTelephone],
                sms_text: `isaanspa Thank you for your payment! OrderID ${orderId} Amount Paid ${amountPaid} EUR`,
                sms_type: "SMS_WORLD",
                sms_sender: "Mirabeau Thai Spa",
            };

            console.log('Sending SMS notification with data:', smsData);
            const response = await fetch('https://py.testproject.work/sms/api/octopush', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(smsData),
            });

            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.error || 'Failed to send SMS notification');
            }

            console.log('SMS notification sent successfully:', responseData);
        } catch (error) {
            console.error('Error sending SMS notification:', error);
        }
    }

    const updateBookingAfterPayment = async (paymentData) => {
        const customerID = localStorage.getItem('userData');
        const bookingUpdateData = [
            {
               "BookingID": parseInt(paymentData.order_id),
                "AmountPaid": paymentData.amount,
                "TotalAmount": parseFloat(subTotalAmount),
                "CustomerID": customerID,
                "PaymentStatus": 9,
                "date": new Date().toISOString().split('T')[0],
                "time": new Date().toTimeString().split(' ')[0]
            }
        ];

        try {
            const token = localStorage.getItem('token') || '';
            if (!token) {
                console.error('No token found. Skipping booking update.');
                return;
            }

            const response = await fetch('https://py.testproject.work/booking/api/edit', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(bookingUpdateData),
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const responseData = await response.json();
            console.log('Booking update response:', responseData);
        } catch (error) {
            console.error('Failed to update booking after payment:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('submitting');

        const token = localStorage.getItem('token') || '';
        if (!token) {
            showRegistrationModal();
            setSubmitStatus('failure');
            return;
        }

        if (!stripe || !elements || !clientSecret) {
            toast.current.show({
                severity: 'error',
                summary: 'Stripe Not Ready',
                detail: 'Stripe or Elements not ready, or clientSecret not obtained. Please try again.',
                life: 5000
            });
            setSubmitStatus('failure');
            return;
        }

        if (!formData.customerEmail) {
            toast.current.show({
                severity: 'error',
                summary: 'Form Validation Failed',
                detail: 'Please provide a valid email before submitting.',
                life: 5000
            });
            setSubmitStatus('failure');
            return;
        }

        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (error) {
            console.error('Payment processing error:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Payment Failed',
                detail: error.message,
                life: 5000
            });
            setSubmitStatus('failure');
        } else if (paymentIntent && paymentIntent.status === 'succeeded') {
            console.log('Payment successful:', paymentIntent.id);
            setSubmitStatus('success');

            const paymentData = {
                order_id: formData.customerReference,
                amount: Math.ceil(parseFloat(totalAmount)),
                subTotalAmount: subTotalAmount,
            };

            await updateBookingAfterPayment(paymentData);
            await clearAllFromIndexedDB(STORE_NAME);
            await sendSmsNotification(paymentData);

            window.location = '/payment/invoice';
        }
    };

    return (
        <div className="payment-form">
            <Toast ref={toast} />

            {!stripe || !elements ? (
                <div>Loading payment form...</div>
            ) : !clientSecret ? (
                <div>Loading payment intent...</div>
            ) : (
                <>
                    <div className="payment-logos">
                        <img src="https://afaa.website/s/c9cae2.svg" alt="Visa Logo" className="payment-logo" />
                        <img src="https://afaa.website/s/e488f6.svg" alt="Stripe Logo" className="payment-logo" />
                    </div>

                    <Form onSubmit={handleSubmit} noValidate>
                        <Form.Group controlId="cardElement" className="mt-3 mb-4">
                            <Form.Label>Card Details</Form.Label>
                            <div style={{ border: '1px solid #ccc', padding: '10px' }}>
                                <CardElement />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="customerEmail">
                            <Form.Label>Customer Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                name="customerEmail" 
                                value={formData.customerEmail} 
                                onChange={handleChange} 
                                required 
                            />
                        </Form.Group>

                        {/* Customer Reference ID ทำงานปกติ */}
                        <Form.Group controlId="customerReference">
                            <Form.Label>Customer Reference ID</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="customerReference" 
                                value={formData.customerReference} 
                                onChange={handleChange} 
                                required 
                                disabled 
                            />
                        </Form.Group>

                        <Button
                            className={`custom-button mt-3 ${submitStatus === 'failure' ? 'p-button-danger' : ''}`}
                            type="submit"
                            disabled={submitStatus === 'submitting'}
                        >
                            {submitStatus === 'submitting' ? 'Submitting...' :
                                submitStatus === 'failure' ? 'Failed - Try Again' : 'Submit Payment'}
                        </Button>
                    </Form>
                </>
            )}
        </div>
    );
};

export default PaymentForm;
