import React from 'react';
import { Link } from 'react-router-dom'; // ต้องการ react-router-dom สำหรับการใช้ Link

const Banner = () => {
  return (
    <div className="banner text-center">
      {/* อาจใช้ภาพหรือสไตล์อื่นๆ เพื่อตกแต่ง */}
      <h1>Welcome to Our Spa</h1>
      <p>Enjoy a relaxing experience with us.</p>
      <Link to="/booking" className="btn booking-btn">Booking Now</Link> {/* Link to "/booking" */}
    </div>
  );
};

export default Banner;
