import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Us</h2>
      <p>Our spa has been providing wellness and beauty treatments since...</p>    
      {/* Include a contact form or more contact information here */}
    </div>
  );
}

export default About;
