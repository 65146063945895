// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/components/CustomSwitchWithLabel.js

import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

const CustomSwitchWithLabel = ({ id, checked, onChange, label }) => {
    const labelStyle = {
        position: 'absolute',
        top: '50%',
        right: '5%', // Keep label on the right
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        fontWeight: 'bold',
        fontSize: '13px',
        zIndex: 1,
        whiteSpace: 'nowrap',
        color: checked ? '#fff59b' : '#554d00',  // Set label color            
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block', width: '85px', height: '35px', marginRight: '10px', }}>
            <InputSwitch
                id={id}
                checked={checked}
                onChange={onChange}
                style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 0 }}
            />
            <span style={labelStyle}>{label}</span>
        </div>
    );
};

export default CustomSwitchWithLabel;
