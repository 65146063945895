// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/forgot.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/spinner.css';
import '../styles/login.css';
import AlertModal from '../components/AlertModal';
import '../styles/modal.css';

const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [alertType, setAlertType] = useState('');

  // Handle the submission of the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate input fields
    if (!email || !telephone || !newPassword) {
      setAlertMessage('Please fill in all fields.');
      setAlertType('error');
      setShowModal(true);
      setIsSubmitting(false);
      return;
    }

    // Send the POST request to the server
    try {
      const response = await fetch('https://py.testproject.work/forgot/api/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, telephone, new_password: newPassword })
      });

      const data = await response.json();
      if (response.ok) {
        setAlertMessage('Password reset successful. Please login with your new password.');
        setAlertType('success');
        setShowModal(true);
        setTimeout(() => { navigate('/login'); }, 3000); // Redirect after 3 seconds
      } else {
        throw new Error(data.message || 'Error resetting password.');
      }
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType('error');
      setShowModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="forgot-page">
      <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
        <div className="card shadow-lg p-4 mb-5 bg-white rounded" style={{ maxWidth: '400px', width: '100%' }}>
          <div className="card-body">
            <h3 className="text-center mb-4">Password Reset</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="telephone" className="form-label">Telephone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="telephone"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="newPassword" className="form-label">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                  {isSubmitting ? 'Resetting...' : 'Reset Password'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal && (
        <AlertModal
         type={alertType} 
         message={alertMessage}
         onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Forgot;
