import React from 'react';
import MySwiper from '../components/MySwiper';
import '../styles/scss/home.scss';
const Home = () => {
  return (
    <div>
      <MySwiper />   
    </div >
  );
}

export default Home;
