//import React, { useState } from 'react';
// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/login.js

import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/spinner.css';
import '../styles/login.css';
import AlertModal from '../components/AlertModal'; // นำเข้า AlertModal
import '../styles/modal.css';
import { useAuth } from '../components/AuthContext';


const Login = () => {
  const { setIsLoggedIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  // สลับแสดง/ซ่อนรหัสผ่าน
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  let loginSuccess = false;

  const handleLogin = async (e) => {
    e.preventDefault();
    //setIsSubmitting(true);

    if (!email || !password) {
      //alert('Please enter both email and password.')
      setAlertMessage('Please Enter Both Email And Password.');
      setShowModal(true);
      setIsSubmitting(false);
      return;
    }

    // ตรวจสอบรูปแบบ email
    if (!/\S+@\S+\.\S+/.test(email)) {
      setAlertMessage('Please Enter A Valid Email Address.');
      setShowModal(true);
      return;
    }

    // ตรวจสอบความยาวของรหัสผ่าน
    if (password.length < 6) {
      setAlertMessage('Password Must Be At Least 6 Characters.');
      setShowModal(true);
      return;
    }  

    setIsSubmitting(true); // ตั้งค่าเฉพาะเมื่อเริ่ม process การ login
   
    try {
      const response = await fetch('https://py.testproject.work/login/api/check', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });


      if (!response.ok) {
        setEmail('');
        setPassword('');
        throw new Error('Network response was not ok.');
      }

      const data = await response.json(); // อ่าน JSON response ครั้งเดียว
      console.log('Response data:', data); // Print ข้อมูลที่ได้รับ

      if (data.message === 'OK') {
        localStorage.setItem('token', data.token); // Store JWT in localStorage
        localStorage.setItem('userData', JSON.stringify(data.user)); // Store user data
        loginSuccess = true; // อัพเดทสถานะการเข้าสู่ระบบ
        //window.location.href = '/profile'; 
        //navigate('/profile');
      } else {
        // จัดการกับข้อความแจ้งเตือนถ้าไม่ 'OK'
        alert(data.message);
      }

      loginSuccess = true;
      if (loginSuccess) {
        setIsLoggedIn(true);
        navigate('/profile'); // หรือการดำเนินการอื่นๆ ที่ต้องการ
      }

    } catch (error) {
      //console.error('Error during login:', error);
      //alert('An error occurred during login.');
      setAlertMessage('An Error Occurred During Login.');
      setShowModal(true);
    } finally {
      setIsSubmitting(false); // ปิด spinner
    }

  };



  return (

    <div className="App">
      <div className="login-page">
        <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
          <div className="card shadow-lg p-4 mb-5 bg-white rounded" style={{ maxWidth: '400px', width: '100%' }}>
            <div className="card-body">
              <h3 className="text-center mb-4"> ISAAN THAI SPA </h3>
              <form onSubmit={handleLogin}>
                <div className="mb-3">
                  <label htmlFor="staticEmail" className="form-label">Enter username</label>
                  <input
                    type="email"
                    className="form-control"
                    id="staticEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="inputPassword" className="form-label">Enter Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id="inputPassword"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                    />
                    <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                      <i className={showPassword ? "fa-solid fa-eye-slash" : "fa-regular fa-eye"}></i>
                    </button>
                  </div>
                </div>
                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-login-index1" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <><i className="fas fa-spinner fa-spin"></i> Logging in...</>
                    ) : (
                      'LOGIN'
                    )}
                  </button>
                  <a className='text-forgot' href='/forgot'>Forgot Password</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <AlertModal
          message={alertMessage}
          onClose={() => setShowModal(false)}
        />
      )}
      
    </div>
  );
}

export default Login;
