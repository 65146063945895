// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/CookiesPolicy.js

import React, { useState, useEffect } from 'react';
import legalData from '../data/legalData.json'; // Import JSON data
import '../styles/cookiePolicy.scss'; // Import the SCSS file for styling


const CookiePolicy = () => {
  const [cookieInfo, setCookieInfo] = useState([]);

  useEffect(() => {
    // Load the "Politique de Cookies" section
    setCookieInfo(legalData.cookiePolicy);
  }, []);

  return (
    <div className="cookie-policy-page">
      <h1>{cookieInfo.title}</h1>
      {cookieInfo.content && cookieInfo.content.map((section, index) => (
        <div key={index} className="section">
          <h2>{section.heading}</h2>
          {/* Split the text by \n\n and render each part as a paragraph */}
          {section.text.split('\n\n').map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CookiePolicy;
