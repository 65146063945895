import React from 'react';
import RegisterForm from '../components/register';  // นำเข้า RegisterForm จาก components

const RegisterPage = () => {
    return (
        <div>
            <div className="container">
                <h2>S'INSCRIRE</h2>

                <RegisterForm />  {/* ใช้ RegisterForm ที่มาจาก components */}

                {/* เนื้อหาเพิ่มเติมหากจำเป็น */}
            </div>
        </div>
    );
}

export default RegisterPage;
