// /var/www/website2023/aaa-aa.site/website2023/s-293-spatime-dev/src/utilities/IndexedDBAll.js
const DB_NAME = 'BookingAppDB';
const STORE_NAME = 'bookings';

// Function to fetch all bookings from IndexedDB
export const fetchAllBookings = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event.target.error);
      reject(event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([STORE_NAME], 'readonly');
      const objectStore = transaction.objectStore(STORE_NAME);
      const allBookingsRequest = objectStore.getAll();

      allBookingsRequest.onerror = (event) => {
        console.error('Error fetching bookings from IndexedDB:', event.target.error);
        reject(event.target.error);
      };

      allBookingsRequest.onsuccess = (event) => {
        resolve(event.target.result); // Returns an array of all booking objects
      };
    };
  });
};
