// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/store/StoreBookingPaymentFormStep.js

// ทำหน้าที่ ที่เก็บข้อมูลสถานะ และ จัดการสถานะ
import { createStore, combineReducers } from 'redux';
import bookingReducer from '../reducers/bookingReducer';

const rootReducer = combineReducers({
  // Define your reducers here
  booking: bookingReducer,
  // other reducers...
});

const store = createStore(rootReducer);

export default store;
