///var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/MentionsLegales.js

import React, { useState, useEffect } from 'react';
import legalData from '../data/legalData.json'; // Import JSON data

const MentionsLegales = () => {
  const [legalInfo, setLegalInfo] = useState([]);

  useEffect(() => {
    // Load the "Mentions Légales" section
    setLegalInfo(legalData.mentionsLegales);
  }, []);

  return (
    <div className="legal-page">
      <h1>{legalInfo.title}</h1>
      {legalInfo.content && legalInfo.content.map((section, index) => (
        <div key={index}>
          <h2>{section.heading}</h2>
          {/* Split the text by \n\n and map each paragraph */}
          {section.text.split('\n\n').map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MentionsLegales;
