// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/OffcanvasBookingStep.js

import React, { useState, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import '../styles/scss/OffcanvasBookingStep.scss';
import { Button } from 'primereact/button';

const OffcanvasBookingStep = ({ position, visible, onHide, selectedBookings, handleSpeedDialSelect }) => {
  const [serviceDetails, setServiceDetails] = useState({});
  const [error, setError] = useState(null); // State to store any error messages

  useEffect(() => {
    const fetchServiceDetails = async () => {
      const details = {};

      for (const booking of selectedBookings) {
        console.log('booking.id:', booking.id);
        try {
          const response = await fetch(`https://py.testproject.work/price/api/price_service?PriceID=${booking.id}&status=1`);
          const data = await response.json();

          // Log the entire response to understand its structure
          console.log('API Response:', data);

          if (!data || !data.results) {
            throw new Error('Invalid API response');
          }

          const serviceData = data.results.find(item => item.PriceID === booking.id);

          if (serviceData) {
            const bookingDate = new Date(booking.date);
            const dayOfWeek = bookingDate.getDay();
            const bookingTime = parseInt(booking.time.split(':')[0], 10);

            // Discount logic
            let priceValue = serviceData.PriceValue;
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && bookingTime >= 11 && bookingTime < 15) { // Monday to Friday, 11h-15h
              priceValue *= 0.85; // Apply a 15% discount
            }

            details[booking.id] = {
              serviceName: serviceData.ServiceName,
              priceValue: priceValue.toFixed(2) + serviceData.symbol // Format and append currency symbol
            };
          } else {
            details[booking.id] = {
              serviceName: 'Service not found',
              priceValue: 'N/A'
            };
          }
        } catch (error) {
          console.error('Error fetching service details:', error);
          setError('Failed to fetch service details. Please try again later.');
          details[booking.id] = {
            serviceName: 'Error fetching service',
            priceValue: 'N/A'
          };
        }
      }

      setServiceDetails(details);
    };

    if (selectedBookings.length > 0) {
      fetchServiceDetails();
    }
  }, [selectedBookings]);

  return (
    <Offcanvas show={visible} onHide={onHide} placement={position}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Selected Bookings</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {error && (
          <Alert variant="danger">
            {error}
          </Alert>
        )}
        {selectedBookings.length > 0 ? (
          selectedBookings.map((booking, index) => {
            const bookingDate = new Date(booking.date);
            const formattedDate = bookingDate.toLocaleDateString('fr-FR', { weekday: 'short', month: 'short', day: 'numeric' });

            return (
              <Alert key={index} variant="secondary">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="product-item">
                    <div className="product-action">
                      <div className="product-name">{serviceDetails[booking.id]?.serviceName || 'Loading...'} {booking.name}</div>
                      <div className="product-duration">{booking.time} {formattedDate}</div>
                      <div className="product-price">{serviceDetails[booking.id]?.priceValue || 'Loading...'}</div>
                    </div>
                  </div>
                  {booking.happyHour && (
                    <Alert variant="danger" className="mb-0 py-1 px-2 small">Happy Hour</Alert>
                  )}
                </div>
              </Alert>
            );
          })
        ) : (
          <div className="text-center">
            <p>No selected bookings found.</p>
          </div>
        )}
        <Button
          label="Paiement"
          icon="pi pi-calendar"
          className="p-button-help p-button-reserver"
          onClick={() => handleSpeedDialSelect('payment')}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasBookingStep;
