// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/order.js
import React, { useState, useEffect } from 'react';
import NavbarProfile from '../components/navbar_profile';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';


import '../styles/ProfileForm.css';

const Order = () => {
    const [bookings, setBookings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const fetchBookings = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const user_ID = localStorage.getItem('userData');
            try {
                const response = await fetch(`https://py.testproject.work/booking/api/view?sort_by=BookingID&sort=desc&CustomerID=${user_ID}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setBookings(data);
                } else {
                    console.error('Failed to fetch bookings:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchBookings();
    }, []);

    const renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        );
    }

    const bookingIDTemplate = (rowData) => {
        // Corrected to use an arrow function to correctly pass BookingID
        const handleInvoiceNavigation = () => {
            // Store the BookingID in localStorage
            localStorage.setItem('localBookingReduxID', rowData.BookingID.toString());
            // Navigate to /payment/invoice in a new tab
            window.open(`/payment/invoice?BookingID=${rowData.BookingID}`, '_blank');
        };
    
        return (
            <Button 
                label={rowData.BookingID.toString()} 
                icon="pi pi-search"
                className="p-button-raised p-button-rounded p-button-success" 
                onClick={handleInvoiceNavigation} // Corrected without passing BookingID directly
            />
        );
    };
    

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    

    return (
        <div className="container my-5">
            <NavbarProfile />
            {isLoading ? (
                <div className="d-flex justify-content-center my-3">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataTable value={bookings} globalFilter={globalFilter} header={renderHeader()} responsiveLayout="scroll">
                    <Column field="BookingID" header="Booking ID" body={bookingIDTemplate} sortable></Column>
                    <Column field="TotalAmount" header="Total Amount" sortable></Column>
                    <Column field="AmountPaid" header="Amount Paid" sortable></Column>
                    <Column field="PaymentStatusName" header="Payment Status" sortable></Column>
                    <Column field="date" header="Date" sortable body={(rowData) => formatDate(rowData.date)}></Column>
                    <Column field="time" header="Time" sortable></Column>
                </DataTable>
            )}
        </div>
    );
};

export default Order;
