///var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/BookingScheduleStep/DataTableBookingTimeTable.js

import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockKeyhole, faLock } from '@fortawesome/free-solid-svg-icons';
import { InputSwitch } from 'primereact/inputswitch';

const DataTableBookingTimeTable = ({ times, selectedDate, temporaryBookings, handleAvailabilityChange, fetchHappyHourData }) => {

  const formattedDate = selectedDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  return (
    <DataTable value={times} className="p-datatable-striped p-datatable-no-header">
      <Column field="time" header="Time" />

      <Column header="Status" body={(rowDataHH) => {
        const happyHourSlots = fetchHappyHourData(selectedDate);
        const isHappyHour = happyHourSlots.some(slot => slot.time === rowDataHH.time && slot.statusHH === 'HEUREUX HEURE');
        return isHappyHour ? <span className="alert alert-lock alert-danger">Happy Hour</span> : null;
      }} />

      <Column body={rowData => {
        const isSwitchChecked = temporaryBookings[formattedDate]?.hasOwnProperty(rowData.time) ?
          temporaryBookings[formattedDate][rowData.time] :
          rowData.status === 'available';

        let statusLabel = rowData.status === 'available' ? 'INDISPONIBLE' : 'DISPONIBLE';

        if (temporaryBookings[formattedDate]?.hasOwnProperty(rowData.time)) {
          statusLabel = isSwitchChecked ? 'CHOISIR' : 'DISPONIBLE';
        }

        const isDisabled = statusLabel === 'INDISPONIBLE';

        let alertClass = '';
        if (statusLabel === 'DISPONIBLE') {
          alertClass = 'alert-dark';
        } else if (statusLabel === 'CHOISIR') {
          alertClass = 'alert-warning';
        } else if (statusLabel === 'INDISPONIBLE') {
          alertClass = 'alert-success';
        }

        return (
          <div className='right'>
            <FontAwesomeIcon icon={isSwitchChecked ? faUnlockKeyhole : faLock} className='mx-3' style={{ color: isSwitchChecked ? '#ff0909' : '#00ab54' }} />
            <InputSwitch
              className='mx-3'
              checked={isSwitchChecked}
              disabled={isDisabled}
              onChange={async (e) => {
                if (!isDisabled) {
                  handleAvailabilityChange(rowData.time, e.value);
                }
              }}
            />
            <span className={`alert alert-lock ${alertClass}`}>{statusLabel}</span>
          </div>
        );
      }} />
    </DataTable>
  );
};

export default DataTableBookingTimeTable;
