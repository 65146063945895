///var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/PrivacyPolicy.js

import React, { useState, useEffect } from 'react';
import legalData from '../data/legalData.json'; // Import JSON data

const PrivacyPolicy = () => {
  const [policyInfo, setPolicyInfo] = useState([]);

  useEffect(() => {
    // Load the "Politique de confidentialité" section
    setPolicyInfo(legalData.privacyPolicy);
  }, []);

  return (
    <div className="policy-page">
      <h1>{policyInfo.title}</h1>
      {policyInfo.content && policyInfo.content.map((section, index) => (
        <div key={index}>
          <h2>{section.heading}</h2>
          {/* Split the text by \n\n and render each part as a paragraph */}
          {section.text.split('\n\n').map((paragraph, idx) => (
            <p key={idx}>{paragraph}</p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
