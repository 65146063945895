// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/utilities/bookingUtils.js

import { API_URLS } from '../api/api';
import { updateBookingSubmissionResponse } from '../actions/bookingActions';
import { formatDateForSubmission } from '../utilities/dateUtils'; // Assuming this utility exists


// Function to fetch price value for a given booking id
const fetchPriceValueForBooking = async (bookingId) => {
    try {
      const response = await fetch(`${API_URLS.PRICE_VIEW}?PriceID=${bookingId}`);
      const data = await response.json();
      const priceValue = data.prices.length > 0 ? data.prices[0].PriceValue : 0;
      return priceValue;
    } catch (error) {
      console.error(`Error fetching price for booking id ${bookingId}:`, error);
      return 0;
    }
  };
  
  // Function to fetch and calculate the total price of selected bookings
  export const fetchAndCalculateTotalPrice = async (selectedBookings) => {
    let totalPrice = 0;
    for (const booking of selectedBookings) {
      const priceValue = await fetchPriceValueForBooking(booking.id);
      totalPrice += parseFloat(priceValue);
    }
    return totalPrice;
  };
  
  // Function to prepare single booking data
  export const prepareSingleBookingData = (totalPrice, user_ID) => {
    const currentDate = new Date();
  
    return {
      CustomerID: user_ID,
      TotalAmount: totalPrice,
      AmountPaid: 0,
      BookingStatus: 5,
      PaymentStatus: 4,
      date: currentDate.toISOString().split('T')[0],
      time: currentDate.toTimeString().split(' ')[0],
    };
  };
  
  
  // Function to get the branch ID from local storage
function getBranchID() {
  const selectedBranch = JSON.parse(localStorage.getItem('selectedBranch'));
  return selectedBranch ? selectedBranch.id : null;
}

// Function to submit booking data
export const submitBookingData = async (bookingData) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  // Get branch ID from local storage
  const branchID = getBranchID();
  
  // Add branch_ID to bookingData
  const dataToSend = {
    ...bookingData,
    branch_ID: branchID, // Include branch_ID in the data sent to the server
  };

  console.log('Sending booking data to server:', JSON.stringify(dataToSend));

  const response = await fetch(API_URLS.BOOKING_CREATE, {
    method: 'POST',
    headers,
    body: JSON.stringify(dataToSend),
  });

  let responseData;
  try {
    responseData = await response.json();
  } catch (error) {
    console.error('Failed to parse response data:', error);
    responseData = { error: 'Failed to parse server response', details: error };
  }

  if (!response.ok) {
    console.error('Server returned an error:', responseData);
  } else {
    if (responseData.message === "Booking created successfully") {
      console.log('Booking created successfully:', responseData.Bookings);
    }
  }

  return responseData;
};


  
  // Function to submit transformed bookings to detail create API
  export const submitTransformedBookingsToDetailCreate = async (transformedBookings) => {
    try {
      console.log('Transformed Bookings:', transformedBookings);
      const response = await fetch(API_URLS.BOOKING_DETAIL_CREATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transformedBookings)
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Response from detail_create API:', responseData);
      } else {
        throw new Error('Failed to submit transformed bookings to detail_create API');
      }
    } catch (error) {
      console.error('Error while submitting transformed bookings to detail_create API:', error);
    }
  };
  
  // Function to transform selected bookings for submission
  export const transformSelectedBookingsForSubmission = async (selectedBookings) => {
    const commonBookingID = selectedBookings.length > 0 && selectedBookings[0].BookingID ? selectedBookings[0].BookingID : 0;
  
    const transformedBookings = await Promise.all(selectedBookings.map(async (booking) => {
      const formattedDate = formatDateForSubmission(booking.date);
      const bookingTime = booking.time;
  
      let promotion_id = 0;
      const bookingDateTime = new Date(`${formattedDate}T${bookingTime}:00`);
      const dayOfWeek = bookingDateTime.getDay();
      const hour = bookingDateTime.getHours();
  
      let priceValue = parseFloat(await fetchPriceValueForBooking(booking.id));
  
      if (dayOfWeek !== 0 && dayOfWeek !== 6 && hour >= 11 && hour < 15) {
        promotion_id = 41;
        priceValue = (priceValue * 0.85).toFixed(2);
      } else {
        promotion_id = 0;
      }
  
      return {
        BookingID: commonBookingID,
        ServiceID: booking.id,
        EmployeeID: 0,
        ServiceDate: formattedDate,
        ServiceTime: bookingTime,
        ServiceStatus: 1,
        TransactionID: 0,
        GiftRecipientID: 0,
        note: booking.note || "Special instructions here",
        promotion_id,
        BookingDetailPrice: priceValue
      };
    }));
  
    console.log('Final transformedBookings:', transformedBookings);
    return transformedBookings;
  };
  
  // Main function to process booking submission
  export const processBookingSubmission = async (selectedBookings, token = null, dispatch) => {
    try {
      const user_ID = localStorage.getItem('user_ID') ? parseInt(localStorage.getItem('user_ID'), 10) : 0;
      const totalPrice = await fetchAndCalculateTotalPrice(selectedBookings);
      const singleBookingData = prepareSingleBookingData(totalPrice, user_ID);
  
      const submissionResponse = await submitBookingData(singleBookingData, token);
      console.log('API response data:', submissionResponse);
  
      if (dispatch) {
        dispatch(updateBookingSubmissionResponse(submissionResponse));
      }
  
      if (submissionResponse.Bookings && submissionResponse.Bookings.length > 0) {
        const mergedBookings = selectedBookings.map((booking, index) => {
          const responseBooking = submissionResponse.Bookings[index];
          return {
            ...booking,
            BookingID: responseBooking ? responseBooking.BookingID : undefined
          };
        });
  
        const transformedBookings = await transformSelectedBookingsForSubmission(mergedBookings);
        console.log("Transformed bookings with BookingID:", transformedBookings);
  
        await submitTransformedBookingsToDetailCreate(transformedBookings);
      }
    } catch (error) {
      console.error('Error during booking submission process:', error);
    }
  };
  
  // Function to handle speed dial actions
  export const handleSpeedDialSelect = async (action, selectedBookings, totalServices, token, saveAndNavigate, dispatch, setIsLoading, setShowOffcanvas) => {
    setIsLoading(true);
  
    if (action === 'payment') {
      if (selectedBookings.length < totalServices) {
        alert(`Please Select ${totalServices} Reserved Services.`);
      } else {
        try {
          if (Array.isArray(selectedBookings) && selectedBookings.length > 0) {
            await processBookingSubmission(selectedBookings, token, dispatch);
            await saveAndNavigate({}, {}, '/payment/step2');
            setIsLoading(false);
            return;
          } else {
            console.error('Failed to submit bookings: Selected bookings are empty or invalid.');
          }
        } catch (error) {
          console.error('Failed to submit bookings:', error);
        }
      }
    } else if (action === 'Invoice') {
      await saveAndNavigate('/payment/invoice');
    } else if (action === 'Shopping') {
      setShowOffcanvas(true);
    }
  
    setIsLoading(false);
  };