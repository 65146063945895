// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/utilities/happyHourUtils.js

export const simulateHappyHourData = () => {
    let happyHourTimes = {};

    for (let month = 1; month <= 12; month++) {
        const daysInMonth = new Date(2024, month, 0).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(2024, month - 1, day);
            const formattedDate = date.toISOString().split('T')[0];

            if (date.getDay() !== 1 && date.getDay() !== 0) {
                happyHourTimes[formattedDate] = [];

                for (let hour = 11; hour < 15; hour++) {
                    happyHourTimes[formattedDate].push({
                        time: `${hour.toString().padStart(2, '0')}:00`,
                        statusHH: "HEUREUX HEURE"
                    });
                    happyHourTimes[formattedDate].push({
                        time: `${hour.toString().padStart(2, '0')}:15`,
                        statusHH: "HEUREUX HEURE"
                    });
                    happyHourTimes[formattedDate].push({
                        time: `${hour.toString().padStart(2, '0')}:30`,
                        statusHH: "HEUREUX HEURE"
                    });
                    if (hour < 14) {
                        happyHourTimes[formattedDate].push({
                            time: `${hour.toString().padStart(2, '0')}:45`,
                            statusHH: "HEUREUX HEURE"
                        });
                    }
                }
            }
        }
    }

    return happyHourTimes;
};
