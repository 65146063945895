// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/profile.js


//import React, { useState, useCallback, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { useDropzone } from 'react-dropzone';
import NavbarProfile from '../components/navbar_profile';
import '../styles/ProfileForm.css';


const Profile = () => {
  const navigate = useNavigate();
  // Define states for each field\
  const [profileData, setProfileData] = useState({
    firstName: '',
    surName: '',
    telephone: '',
    email: '',
    contact: '',
    statusID: '',
    positionID: '',
    profileImage: '',
    birthday: ''
    // Add other fields if necessary
  });

  // ประกาศ state สำหรับเก็บข้อมูลรูปภาพ
  const [setProfileImage] = useState(null);
  //const [profileImage, setProfileImage] = useState(null);
  //const [birthday, setBirthday] = useState('');


  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    // Assuming the server returns the date in a format parseable by new Date()
    const date = new Date(dateString);
    return date.toISOString().substring(0, 10);  // This will be in YYYY-MM-DD format
  };


  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('token');
      const user_ID = localStorage.getItem('userData'); 

      if (!token || !user_ID || user_ID === 'null') {
        console.error('No token found');
        navigate('/login');
        return;
      }

      console.log(token)
      console.log(user_ID)

      try {
        const response = await fetch(`https://py.testproject.work/register/api/user?user_ID=${user_ID}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        //console.log(response)


        if (response.ok) {
          const data = await response.json();

          const birthdayForInput = formatDateForInput(data.birthday);
          console.log('Formatted Birthday:', birthdayForInput);

          setProfileData({
            firstName: data.first_name,
            surName: data.sur_name,
            telephone: data.telephone,
            email: data.email,
            contact: data.contact,
            statusID: data.status_ID,
            birthday: birthdayForInput,
            image: data.image,
            positionID: data.position_ID
          });
        } else {
          // Handle errors
          console.error(`Failed to fetch profile data: ${response.status} ${response.statusText}`);
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, [navigate]);

  // สร้าง function เมื่อมีไฟล์รูปภาพถูกเลือก
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file)); // สร้าง URL สำหรับรูปภาพเพื่อแสดงตัวอย่าง
    }
  };

  // Function and hook for handling file drop
  // Handle the files, e.g., send them to the server here
  // const onDrop = useCallback(acceptedFiles => {
  //   console.log(acceptedFiles);
  // }, []);

  //const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleInputChange = (field, value) => {
    setProfileData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  // Function to handle profile image preview
  // const handleProfileImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setProfileData({ ...profileData, profileImage: URL.createObjectURL(file) });
  //   }
  // };

  // const formatDate = (dateString) => {
  //   const [year, month, day] = dateString.split('-');
  //   return `${day}/${month}/${year}`;
  // };


  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // เริ่มแสดง spinner
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userData');

    if (!token || !userId) {
      console.error('No token or user ID found');
      return;
    }

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');

    try {
      const response = await fetch(`https://py.testproject.work/register/api/edit?user_ID=${userId}`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          first_name: profileData.firstName,
          sur_name: profileData.surName,
          telephone: profileData.telephone,
          email: profileData.email,
          contact: profileData.contact,
          image: profileData.image,
          birthday: profileData.birthday
        })
      });


      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
  };


  return (
    <div className="container my-5">
      <NavbarProfile />

      {isLoading && (
        <div className="d-flex justify-content-center my-3">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div className="profile-form-card">
        <div className="profile-form-body">

          {/* Profile Image Preview */}
          {profileData.profileImage && (
            <div className="profile-image-container">
              <img src={profileData.profileImage} alt="Profile Preview" className="profile-preview w100" />
            </div>
          )}

          <form onSubmit={handleSubmit}>

            {/* Profile Image Upload */}
            <div className="mb-3">
              <label htmlFor="profileImageInput" className="form-label">Upload Profile Image</label>
              <input type="file" 
              className="form-control" 
              id="profileImageInput" 
              onChange={handleFileChange} />
            </div>


            {/* First Name Field */}
            <div className="mb-3">
              <label htmlFor="firstNameInput" className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                id="firstNameInput"
                value={profileData.firstName}
                onChange={(e) => handleInputChange('firstName', e.target.value)}
                required
              />
            </div>

            {/* Surname Field */}
            <div className="mb-3">
              <label htmlFor="surNameInput" className="form-label">Surname</label>
              <input
                type="text"
                className="form-control"
                id="surNameInput"
                value={profileData.surName}
                onChange={(e) => handleInputChange('surName', e.target.value)}
                required
              />
            </div>

            {/* Telephone Field */}
            <div className="mb-3">
              <label htmlFor="telephoneInput" className="form-label">Telephone</label>
              <input
                type="tel"
                className="form-control"
                id="telephoneInput"
                value={profileData.telephone}
                onChange={(e) => handleInputChange('telephone', e.target.value)}
                required
              />
            </div>

            {/* Email Field */}
            <div className="mb-3">
              <label htmlFor="emailInput" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="emailInput"
                value={profileData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                required
              />
            </div>

            {/* Contact Field เป็น textarea */}
            <div className="mb-3">
              <label htmlFor="contactInput" className="form-label">Contact</label>
              <textarea
                className="form-control"
                id="contactInput"
                value={profileData.contact}
                onChange={(e) => handleInputChange('contact', e.target.value)}
              />
            </div>

            {/* ช่องวันเกิด */}
            <div className="mb-3">
              <label htmlFor="birthDateInput" className="form-label">Birth Date</label>
              <input
                type="date"
                className="form-control"
                id="birthDateInput"
                placeholder="dd/mm/yyyy"
                value={profileData.birthday}
                onChange={(e) => handleInputChange('birthday', e.target.value)}
                required
              />
            </div>

            {/* Submit Button */}



            {/* Submit Button */}
            <div className="d-grid">
              <button type="submit" className="btn btn-primary btn-login-index1">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;