// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/reducers/bookingReducer.js

//การทำงานของหน้านี้ จำตรวจสอบประเภทของการกระทำ และอัปเดตสถานะตามคำสั่ง
// Initial state of the booking part of the store
const initialState = {
    submissionResponse: null, // This will store the response of a booking submission
  };
  
  // Action type constant
  const UPDATE_BOOKING_SUBMISSION_RESPONSE = 'UPDATE_BOOKING_SUBMISSION_RESPONSE';
  
  // The booking reducer function
  const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_BOOKING_SUBMISSION_RESPONSE:
        // Update the state with the new submission response
        return {
          ...state,
          submissionResponse: action.payload,
        };
      default:
        // Always return the current state for any unknown actions
        return state;
    }
  };
  
  export default bookingReducer;
  