//var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/actions/bookingActions.js

// Action Types
//หลักการทำงาน bookingActions ทำหน้าที่เป็นค่าคงที่ ID 
export const UPDATE_BOOKING_SUBMISSION_RESPONSE = 'UPDATE_BOOKING_SUBMISSION_RESPONSE';

// Action Creator for updating booking submission response
export const updateBookingSubmissionResponse = (submissionResponse) => ({
  type: UPDATE_BOOKING_SUBMISSION_RESPONSE,
  payload: submissionResponse,
});