// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/utilities/indexedDBUtils.js

const DB_NAME = 'BookingAppDB';
const STORE_NAME = 'bookings';
const DB_VERSION = 1;

export const initDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error('IndexedDB error:', event.target.error);
      reject('Error opening IndexedDB.');
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

export const saveToIndexedDB = async (objectStoreName, data) => {
  const db = await initDB();
  const transaction = db.transaction([objectStoreName], 'readwrite');
  const objectStore = transaction.objectStore(objectStoreName);

  const request = objectStore.put(data); // สมมติว่าข้อมูลมีคีย์ 'id'

  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      console.error('Error saving to IndexedDB:', event.target.error);
      reject(event.target.error);
    };

    request.onsuccess = () => {
      resolve(request.result);
    };
  });
};

export const prepareAndSaveDataToIndexedDB = async (originalData, priceID, additionalInfo) => {
  const preparedData = {
    id: priceID, // Unique identifier for the entry
    name: additionalInfo.name, // The name associated with the booking
    date: additionalInfo.date, // The date of the booking
    time: additionalInfo.time, // The time of the booking
    data: originalData, // The original data, including checkbox states
  };

  // Save the prepared data to IndexedDB
  return await saveToIndexedDB(STORE_NAME, preparedData);
};

export const fetchFromIndexedDB = async (key) => {
  const db = await initDB();
  const transaction = db.transaction([STORE_NAME], 'readonly');
  const objectStore = transaction.objectStore(STORE_NAME);
  const request = objectStore.get(key);

  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      console.error('Error fetching from IndexedDB:', event.target.error);
      reject('Error fetching data.');
    };

    request.onsuccess = () => {
      resolve(request.result ? request.result.data : null);
    };
  });
};


// อ่านข้อมูลIndexedDB
export const printTrueInputSwitchesFromIndexedDB = async () => {
  const db = await initDB();
  const transaction = db.transaction([STORE_NAME], 'readonly');
  const store = transaction.objectStore(STORE_NAME);
  const allRecords = store.getAll();

  return new Promise((resolve, reject) => {
    allRecords.onsuccess = () => {
      const trueSwitchIDs = allRecords.result.map(record => record.id); // Select only the 'id' key from each item
      resolve(trueSwitchIDs);
    };
    allRecords.onerror = () => {
      reject(allRecords.error);
    };
  });
};


export const deleteFromIndexedDB = async (id) => {
  const db = await initDB();
  const transaction = db.transaction([STORE_NAME], 'readwrite');
  const store = transaction.objectStore(STORE_NAME);

  return new Promise((resolve, reject) => {
    const request = store.delete(id);
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = (e) => {
      console.error('Error deleting from IndexedDB:', e.target.error);
      reject(e.target.error);
    };
  });
};




