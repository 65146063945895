// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/components/InvoiceComponent.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/scss/InvoiceComponent.scss';
import InvoiceDataTranslations from '../data/InvoiceDataTranslations.json';
import FixedRateConnAPI from '../data/FixedRateConnAPI.json'
import StoreConstants from '../data/StoreConstant.json';

const InvoiceComponent = ({ invoiceData, language }) => { // Default language set to 'EN'
  // Destructure your invoiceData props as needed
  const { clientDetails, notes, Description, Quantity, Price, service } = invoiceData;
  const t = InvoiceDataTranslations[language]; // Use the translations for the specified language
  const taxRate = FixedRateConnAPI.tax;

  const [bookingDetails, setBookingDetails] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [bookingDetailsExtended, setBookingDetailsExtended] = useState([]);

  // const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);
  const [calculatedTax, setCalculatedTax] = useState(0);


  useEffect(() => {
    const fetchDetails = async () => {
      const bookingID = localStorage.getItem('localBookingReduxID');
      const token = localStorage.getItem('token');

      try {
        // API BookingID
        const bookingResponse = await fetch(`https://py.testproject.work/booking/api/view?sort_by=BookingID&sort=desc&BookingID=${bookingID}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const bookingData = await bookingResponse.json();        
        console.log('bookingData :', bookingData);

        // If there are booking details, proceed to fetch additional information
        if (bookingData.length > 0) {
          setBookingDetails(bookingData[0]); // Assuming bookingData[0] has the relevant booking info  
          
        // Convert string values to numbers as necessary
      
        //const fetchedTotalAmount = parseFloat(bookingData[0].TotalAmount);
        const fetchedAmountPaid = parseFloat(bookingData[0].AmountPaid);

        // setTotalAmount(fetchedTotalAmount);
        setAmountPaid(fetchedAmountPaid);

        // Calculate tax based on TotalAmount and taxRate
        const tax = (fetchedAmountPaid * FixedRateConnAPI.tax) / 100;
        setCalculatedTax(tax);          

        const fetchedTotalAmount1 = parseFloat(bookingData[0].TotalAmount);
        setTotalAmount1(fetchedTotalAmount1);
       

          // Fetch customer details based on the customer ID from the booking data
          const customerID = bookingData[0].CustomerID;
          const customerResponse = await fetch(`https://py.testproject.work/register/api/user?user_ID=${customerID}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          const customerData = await customerResponse.json();
          setCustomerDetails(customerData); // Update state with the fetched customer details

          // Fetch extended booking details including service names and price types
          const response = await fetch(`https://py.testproject.work/booking/api/detail_view?BookingID=${bookingID}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch booking details with status: ${response.status}`);
          }
          const bookingDetailsJson = await response.json();

          const extendedDetails = await Promise.all(bookingDetailsJson.map(async (detail) => {
            const priceResponse = await fetch(`https://py.testproject.work/price/api/price_service?PriceID=${detail.ServiceID}`);
            if (!priceResponse.ok) {
              throw new Error(`Failed to fetch price details for ServiceID ${detail.ServiceID}`);
            }
            const priceJson = await priceResponse.json();
            ///console.log("Price details for ServiceID:", detail.ServiceID, priceJson); 
            return {
              ...detail,
              ServiceName: priceJson.results[0].ServiceName,
              PriceType: priceJson.results[0].PriceType,
              PriceValue: priceJson.results[0].PriceValue
            };
          }));
          
          setBookingDetailsExtended(extendedDetails);

          setBookingDetailsExtended(extendedDetails); // Update state with the combined booking and price details
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, []); // Adjust the dependency array as needed

  return (
    <div className="invoice-container">
      {/* Header Section */}
      <header className="invoice-header">
        {/* Company Logo */}
        <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="invoice-logo" />
        {/* Invoice Title */}
        <h1>{t['INVOICE']}</h1> {/* Use the translated invoice title */}
      </header>

      {/* Client Details Section */}
      <section className="client-details">
        {/* Client Information */}

        <div className='text-left'>
          <strong>{t['INVOICE_TO']}</strong>
          <p>{customerDetails?.first_name} {customerDetails?.sur_name}</p>

          <div className='line-index'></div>

          <strong>{t['CONTACT_PERSON']}</strong>

          <p className='bottom-unset'>{t['PHONE']}: {customerDetails?.telephone}</p>
          <p className='bottom-unset'>{t['EMAIL']}: {customerDetails?.email}</p>
          <p>{t['ADDRESS']}: {customerDetails?.contact}</p>

        </div>

        {/* Invoice Information */}
        <div className='text-left'>
          <p className='bottom-unset'>{t['INVOICE_NO']}: {bookingDetails?.BookingID}</p>
          <p>{t['INVOICE_DATE']}: {bookingDetails?.date}</p>

          <div className='line-index'></div>
          <strong> {t['PAYMENT_METHOD']} </strong>
          <p className='bottom-unset'>{t['ORDER_ID']}: {bookingDetails?.BookingID}</p>
          <p className='bottom-unset'>{t['ACCOUNT_NAME']}: {customerDetails?.first_name} {customerDetails?.sur_name} </p>
          <p> {t['STATUS']}: {bookingDetails?.PaymentStatusName}</p>

        </div>
      </section>

      {/* Invoice Items Section */}
      <table className="invoice-items">
        <thead>
          <tr>
            <th>#</th>
            <th> {t['DESCRIPTION']} {Description} </th>
            <th> {t['QUANTITY']} {Quantity} </th>
            <th> {t['SERVICE']} {service} </th>
            <th> {t['PRICE']} {Price} </th>
          </tr>
        </thead>
        <tbody>
          {bookingDetailsExtended.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.ServiceName || 'N/A'}</td> {/* แสดง 'N/A' ถ้า ServiceName เป็น undefined */}
              <td>{`${new Date(item.ServiceDate).toLocaleDateString()} ${item.ServiceTime}`}</td> {/* รูปแบบวันที่และเวลา */}
              <td>{item.PriceType || 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceType เป็น undefined */}
              <td>{item.BookingDetailPrice ? `${item.BookingDetailPrice} €` : 'N/A'}</td> {/* แสดง 'N/A' ถ้า PriceValue เป็น undefined */}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="row mt-4">

        <div className="col-6">
          <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="footer-logo" />
          <p className='bottom-unset'>{clientDetails.name}</p>
          <p>{clientDetails.address}</p>
          <div className='line-index'></div>
          {notes}
        </div>

        <div className="col-3">
          <p className="grid-item sub-total-label">{t['SUB_TOTAL']}</p>
          <p className="grid-item sub-total-label">{t['TAX']} ({taxRate}%)</p>
          <p className="grid-item sub-total-label">{t['TOTAL_TRA']} </p>
        </div>

        <div className="col-3">
          <p className='grid-item' >{totalAmount1}</p>
          {/* <p className='grid-item' >{totalAmount}</p> */}
          <p className='grid-item' >{calculatedTax}</p>
          <p className='grid-item' >{amountPaid}</p>
        </div>
      </div>


      <div className='line-index'></div>

      <div className='mt-2' >
        <div className="legal-notes text-left">
          <strong >{t['NOTES_IMPORTANTES']} :</strong>
          <p> {t['NOTES_IMPORTANT']}</p>
        </div>



        <div className="row mt-4">
          <div className="col-5">
            <p className="icon-text">
              <i className="pi pi-map-marker large-icon"></i>
              <span>{StoreConstants.address}</span>
            </p>
          </div>

          <div className="col-3">
            <p className="icon-text">
              <i className="pi pi-phone large-icon"></i>
              <span>{StoreConstants.phone}</span>
            </p>
          </div>

          <div className="col-4">
            <p className="icon-text">
              <i className="pi pi-envelope large-icon"></i>
              <span>{StoreConstants.email}</span>
            </p>
          </div>
        </div>


        <div className='line-index'></div>
      </div>


    </div>
  );
};

// Prop types validation
InvoiceComponent.propTypes = {
  invoiceData: PropTypes.shape({
    invoiceNumber: PropTypes.string.isRequired,
    invoiceDate: PropTypes.string.isRequired,
    clientDetails: PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      phone: PropTypes.string, // Assuming phone is optional, adjust as necessary
    }).isRequired,
    paymentDetails: PropTypes.shape({
      orderId: PropTypes.string, // Assuming orderId is a string, adjust as necessary
      accountName: PropTypes.string, // Assuming accountName is a string, adjust as necessary
      status: PropTypes.string.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired, // Assuming quantity is a number, adjust as necessary
      price: PropTypes.number.isRequired, // Changed to expect a number
      service: PropTypes.string, // Assuming service is a string, adjust as necessary
    })).isRequired,
    notes: PropTypes.string.isRequired,
    subtotal: PropTypes.string.isRequired,
    tax: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string, // Assuming language is a string prop
};



export default InvoiceComponent;
